import { Box, Container, useMediaQuery } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import Marista from 'images/tim.png';
import React from 'react';
import {
  Link as RouterLink,
  matchPath,
  Redirect,
  useLocation,
} from 'react-router-dom';
import config from 'routes/config';
import { Main } from './styles';

function LoggedLayout(props) {
  const { children } = props;
  let location = useLocation();
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

  const activeRoute = Object.keys(config)
    .map(key => config[key])
    .flat()
    .find(route => matchPath(location.pathname, route));

  if (!activeRoute) {
    return <Redirect to="/" />;
  }

  return (
    <>
      <CssBaseline />
      <Container
        id="menu"
        style={{
          maxWidth: 'none',
          padding: '0 15%',
        }}
      >
        <Box direction="row" justifyContent="center" alignItems="center">
          <RouterLink to="/">
            <img
              draggable={false}
              style={{ height: isMobile ? 30 : 60, margin: '1rem' }}
              src={Marista}
              alt="Toti Agência"
            />
          </RouterLink>
        </Box>
      </Container>
      <Main style={{ backgroundColor: '#fafafa' }}>{children}</Main>
    </>
  );
}

export default LoggedLayout;
