import styled, { css } from 'styled-components';

export const Main = styled.main`
  background-color: #fff;
  flex-grow: 1;
  padding-top: ${props => props.theme.spacing(2)}px;
  ${props => props.darkBlue && `background-color: #042198;`}
`;

export const List = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  color: #fff;
  margin: 0 0 0 30px;
  padding: 0;
  a,
  button {
    display: flex;
    align-items: center;
    height: 100px;
    padding: 0 15px;
    font-size: 16px;
    border-top: 1px solid transparent;
  }
`;

export const ListItem = styled.li`
  ${props =>
    props.selected &&
    css`
      a {
        border-top: 1px solid #fff;
      }
    `}
`;
