import { lazy } from 'react';

import Login from 'pages/Login';

// Logged Pages
const Dashboard = lazy(() => import('pages/Dashboard'));
const Users = lazy(() => import('pages/Users'));

const config = {
  public: [
    {
      path: '/login',
      component: Login,
      exact: true,
    },
  ],
  protected: [
    {
      path: '/',
      component: Dashboard,
      title: 'Início',
      exact: true,
    },
    {
      path: '/usuarios',
      component: Users,
      title: 'Usuarios',
      exact: true,
    },
  ],
};

export default config;
