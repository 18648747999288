import Centered from 'components/Centered';
import { login } from 'contexts/auth';
import LoginForm from 'forms/LoginForm';
import Inovando from 'images/tim.png';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FormContainer } from 'styles/global';

function Login() {
  const history = useHistory();

  const onSubmit = async values => {
    try {
      await login(values);
      history.push('/usuarios');
    } catch (error) {
      toast.error(
        'Não foi possível realizar o login, confira as credenciais informadas.',
      );
    }
  };

  return (
    <Centered column>
      <img
        draggable={false}
        style={{ width: 200, marginBottom: 20 }}
        src={Inovando}
        alt="Toti Agência"
      />
      <FormContainer>
        <LoginForm onSubmit={onSubmit} />
      </FormContainer>
    </Centered>
  );
}

export default Login;
