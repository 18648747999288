import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  typography: {
    h2: {
      fontWeight: 500,
    },
  },

  overrides: {
    MUIDataTableSearch: {
      searchText: {
        display: 'flex',
        justifyContent: 'center',
      },
    },
    MUIDataTableHeadCell: {
      sortAction: {
        alignItems: 'center',
      },
    },
  },
  palette: {
    palette: {
      primary: {
        light: '#3fb569',
        main: '#3fb569',
        dark: '#3fb569',
        contrastText: '#fff',
      },
      secondary: {
        light: '#ff7961',
        main: '#f44336',
        dark: '#ba000d',
        contrastText: '#000',
      },
    },
  },
});

export default theme;
