import React from 'react';
import { Route } from 'react-router-dom';

function PrivateRoute({ children, ...rest }) {
  // const { signed } = useAuth();

  return (
    <Route
      {...rest}
      render={
        () =>
          // signed ? (
          children
        // ) : (
        //   <Redirect
        //     to={{
        //       pathname: '/login',
        //       state: { from: location },
        //     }}
        //   />
        // )
      }
    />
  );
}

export default PrivateRoute;
