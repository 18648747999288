import React, { createContext, useContext, useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { toast } from 'react-toastify';
import { auth, firestore } from 'services/firebase';

const AuthContext = createContext({});

export function login(values) {
  return auth.signInWithEmailAndPassword(values.email, values.password);
}

export const AuthProvider = ({ children }) => {
  const [user, loading] = useAuthState(auth);
  const [userFirestore, setUserFirestore] = useState({});
  const [userFirestoreLoading, setUserFirestoreLoading] = useState(true);

  const computed = { ...userFirestore, ...user };

  useEffect(() => {
    if (!user?.uid) {
      setUserFirestore({});
      return;
    }

    async function getUser() {
      try {
        const response = await firestore.doc(`users/${user.uid}`).get();
        setUserFirestore(response.data());
      } finally {
        setUserFirestoreLoading(false);
      }
    }

    getUser();
  }, [user?.uid]);

  async function getUser() {
    const response = await firestore.doc(`users/${user.uid}`).get();
    const data = response.data();
    setUserFirestore(data);
    return data;
  }

  async function signIn(values) {
    try {
      await login(values);
    } catch (error) {
      toast.error(error.message, {
        autoClose: 5000,
      });
      return Promise.reject(error.message);
    }
  }

  async function signOut() {
    await auth.signOut();
  }

  return (
    <AuthContext.Provider
      value={{
        signed: !!user,
        user: computed,
        signIn,
        signOut,
        loading,
        userLoading: userFirestoreLoading,
        getUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  const context = useContext(AuthContext);

  return context;
}
